@charset "UTF-8";
/* ================================================================= 
 License : e-TRUST Inc.
 File name : common_style.css
 Style : common_style
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.8em;
  font-weight: 500;
  color: #333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow-x: hidden;
  letter-spacing: 0.05em;
  font-size: 16px;
}

.inner {
  margin: 0 auto;
  max-width: 1000px;
}

.inner2 {
  margin: 0 auto;
  max-width: 520px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

@media only screen and (max-width: 834px) {
  #contents_wrap {
    margin-top: 30px;
  }
}

#contents {
  width: 100%;
  max-width: 1000px;
  padding: 0 0 100px 0;
}

@media only screen and (max-width: 1200px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 834px) {
  #contents {
    padding: 0 10px 50px 10px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #1f4589;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

.fixed {
  position: fixed !important;
  z-index: 11 !important;
  top: 0px !important;
  left: 0 !important;
  width: 100% !important;
}

.fixed .clone-nav {
  margin-right: 30px;
}

@media only screen and (max-width: 640px) {
  .fixed .clone-nav {
    display: none;
  }
}

.fixed .clone-nav li a {
  font-size: 16px;
}

.fixed #hednav {
  display: block !important;
}

.fixed2 {
  position: fixed !important;
  z-index: 11 !important;
  top: 0px !important;
  left: 0 !important;
  width: 100% !important;
  background: #fff;
}

#header {
  padding: 30px 0;
  z-index: 10;
}

@media only screen and (max-width: 1200px) {
  #header {
    padding: 30px 10px;
  }
}

@media only screen and (max-width: 1000px) {
  #header {
    padding: 10px;
  }
}

#header .inner {
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
}

#header .flxL {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#header .flxL #siteID {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

#header .flxL #siteID img {
  width: 100% !important;
  max-width: calc( 652px / 2) !important;
}

#header .flxR {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  #header .flxR {
    justify-content: flex-end;
  }
}

#header .flxR .hedinfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 1000px) {
  #header .flxR .hedinfo .tel2 {
    display: none;
  }
}

#header .flxR .hedinfo .tel2 a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #222;
  font-weight: 700;
}

#header .flxR .hedinfo .tel2 i {
  font-weight: 800;
  font-size: 10px;
  color: #1f4589;
  line-height: 1;
  margin-right: 7px;
}

#header .flxR .hedinfo .tel2 .txt {
  font-weight: 700;
}

#header .flxR .hedinfo .tel2 span {
  font-size: 15px;
  margin-right: 10px;
}

#header .flxR .hedinfo .fax {
  margin-left: 20px;
}

@media only screen and (max-width: 1000px) {
  #header .flxR .hedinfo .fax {
    display: none;
  }
}

#header .flxR .hedinfo .fax .txt {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #222;
  font-weight: 700;
}

#header .flxR .hedinfo .fax i {
  font-weight: 800;
  font-size: 10px;
  color: #1f4589;
  line-height: 1;
  margin-right: 7px;
}

#header .flxR .hedinfo .fax span {
  font-size: 15px;
  margin-right: 10px;
}

#header .flxR .hedinfo .cont {
  margin-left: 20px;
}

@media only screen and (max-width: 1000px) {
  #header .flxR .hedinfo .cont {
    margin-left: 0;
    margin-right: 10px;
  }
}

#header .flxR .hedinfo .cont a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  background: #1f4589;
  opacity: 1;
  height: 100%;
}

#header .flxR .hedinfo .cont a:hover {
  background: #0a1e42;
}

@media only screen and (max-width: 640px) {
  #header .flxR .hedinfo .cont a {
    padding: 10px;
    width: 58px;
    height: 58px;
  }
}

#header .flxR .hedinfo .cont i {
  font-weight: 800;
  font-size: 18px;
  color: #fff;
  line-height: 1;
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  #header .flxR .hedinfo .cont i {
    margin-right: 0;
  }
}

#header .flxR .hedinfo .cont .txt {
  font-weight: 800;
  font-size: 16px;
  color: #fff;
  line-height: 1;
}

@media only screen and (max-width: 640px) {
  #header .flxR .hedinfo .cont .txt {
    display: none;
  }
}

.clone-wrap {
  background: #21427c;
}

@media only screen and (max-width: 834px) {
  .clone-wrap {
    display: none;
  }
}

.clone-wrap .clone-nav {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}

@media only screen and (max-width: 1000px) {
  .clone-wrap .clone-nav .bp1000none {
    display: none;
  }
}

.clone-wrap .clone-nav > li {
  width: 100%;
  border-right: 1px solid #fff;
  line-height: 1;
}

.clone-wrap .clone-nav > li:nth-child(3) {
  width: 140%;
}

.clone-wrap .clone-nav > li:last-child {
  border-right: 0;
  width: 130%;
}

.clone-wrap .clone-nav > li.drop {
  position: relative;
}

.clone-wrap .clone-nav > li > ul {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
}

.clone-wrap .clone-nav > li > ul > li a {
  display: block;
  padding: 15px 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}

.clone-wrap .clone-nav > li > p {
  padding: 10px 0;
  font-weight: 700;
  display: block;
  font-size: 16px;
  color: #fff;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .clone-wrap .clone-nav > li > p {
    padding: 0 10px;
  }
}

.clone-wrap .clone-nav > li > a {
  padding: 10px 0;
  font-weight: 700;
  display: block;
  font-size: 16px;
  color: #fff;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .clone-wrap .clone-nav > li > a {
    padding: 0 10px;
  }
}

.clone-wrap .clone-nav > li > a:after {
  position: absolute;
  bottom: -10px;
  left: 0;
  content: '';
  width: 100%;
  height: 2px;
  background: #fff;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

.clone-wrap .clone-nav > li > a:hover {
  opacity: 1 !important;
}

.clone-wrap .clone-nav > li > a:hover:after {
  transform: scale(1, 1);
}

.clone-wrap .clone-nav > li > a span {
  display: block;
  font-size: 13px;
  color: #1f4589;
  margin-top: 5px;
}

.clone-wrap .clone-nav .fixnone {
  display: none;
}

.clone-wrap .clone-nav.sns {
  display: none;
}

.global-menu-btn {
  cursor: pointer;
  height: 58px;
  width: 58px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 11;
  color: #0a1e42;
  line-height: 1;
  transition: 1s;
  color: #fff;
  background: #0a1e42;
}

.global-menu-btn:hover {
  color: #fff;
  background: #1f4589;
}

.global-menu-btn::before {
  font-size: 2.4em;
  content: "\f0c9";
  line-height: 1;
  font-family: "Font Awesome 5 pro";
  font-weight: 400;
}

.global-menu-btn i {
  margin-top: 3px;
  display: block;
  line-height: 1;
}

.global-menu-close-btn {
  display: block;
  height: 100px;
  width: 100px;
  margin-left: auto;
  background: #f4f7f6;
  border-left: 1px solid #E6EAEA;
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn {
    height: 70px;
    width: 70px;
  }
}

.global-menu-close-btn:before {
  content: "\f00d";
  line-height: 32px;
  font-family: "Font Awesome 5 pro";
  font-size: 1.3rem;
  font-weight: 300;
  display: block;
  width: 100%;
  color: #000;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (-ms-high-contrast: none) {
  .global-menu-close-btn:before {
    display: none;
  }
}

.global-menu-close-btn i {
  display: none;
}

.navdamy {
  width: 100%;
  height: 100%;
  position: absolute;
}

#hednav {
  margin-bottom: 0;
  justify-content: center;
  height: 58px;
  width: 58px;
  z-index: 11;
  margin: 0;
  display: none;
}

@media only screen and (max-width: 1000px) {
  #hednav {
    display: block;
  }
}

#hednav .wrap {
  visibility: hidden;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  background: rgba(0, 0, 0, 0.6) !important;
  display: block !important;
  position: fixed;
  width: 100% !important;
  height: 100%;
  top: 0;
  right: 0;
}

#hednav .wrap .navwrap {
  display: block !important;
  background: #eef2f2;
  width: 80%;
  height: 100%;
  opacity: 0;
  margin-left: auto;
  -webkit-transform: translateX(240%);
  -ms-transform: translateX(240%);
  transform: translateX(240%);
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

@media only screen and (max-width: 834px) {
  #hednav .wrap .navwrap {
    width: 80%;
  }
}

#hednav .wrap .navwrap #hed_info {
  border-bottom: 1px solid #E6EAEA;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
}

#hednav .wrap .navwrap #hed_info .tel2 {
  margin: 15px 0;
}

#hednav .wrap .navwrap #hed_info .hoverbtn {
  width: 100%;
}

#hednav .wrap .navwrap #hed_info .hoverbtn a {
  background: #0a1e42;
  opacity: 1 !important;
  padding: 25px 20px;
  transition: 1s;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  color: #fff;
  font-weight: 700;
  font-size: 25px;
  font-size: 2.08333vw;
}

@media only screen and (min-width: 1600px) {
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    font-size: 25px;
  }
}

@media only screen and (max-width: 1200px) {
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    font-size: 20px;
  }
}

#hednav .wrap .navwrap #hed_info .hoverbtn a:hover {
  background: #1f4589;
}

#hednav .wrap .navwrap #hed_info .hoverbtn a i {
  vertical-align: middle;
  margin-right: 10px;
  font-size: 25px;
  font-size: 2.08333vw;
}

@media only screen and (min-width: 1600px) {
  #hednav .wrap .navwrap #hed_info .hoverbtn a i {
    font-size: 25px;
  }
}

@media only screen and (max-width: 1200px) {
  #hednav .wrap .navwrap #hed_info .hoverbtn a i {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  #hednav .wrap .navwrap #hed_info .hoverbtn a i {
    font-size: 20px;
  }
}

#hednav .wrap .navwrap #hed_info .hoverbtn a span {
  line-height: 1;
}

#hednav .wrap .navwrap #nav_global {
  width: 100%;
  margin-top: 0 !important;
  background: none;
}

#hednav .wrap .navwrap #nav_global > ul {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
}

#hednav .wrap .navwrap #nav_global > ul .pcnone {
  display: block !important;
}

#hednav .wrap .navwrap #nav_global > ul > li {
  text-align: left;
  position: relative;
  width: 100%;
  border-bottom: solid 1px #ddd;
}

#hednav .wrap .navwrap #nav_global > ul > li a {
  line-height: 1.7em;
  color: #222;
  font-weight: 700;
  display: block;
  padding: 25px 36px;
}

#hednav .wrap .navwrap #nav_global > ul > li a:hover {
  background: #ccc;
}

#hednav .wrap .navwrap #nav_global > ul > li ul {
  width: 100%;
  position: static;
  background: none;
}

#hednav .wrap .navwrap #nav_global > ul > li ul li a {
  background: #f7f7f7;
  color: #333;
  font-weight: 500;
  display: block;
}

#hednav .wrap .navwrap #nav_global > ul > li p {
  line-height: 1.7em;
  color: #222;
  font-weight: 700;
  display: block;
  padding: 25px 36px;
}

#hednav .wrap .navwrap #nav_global > ul > li p:hover {
  background: #ccc;
}

#hednav .wrap .navwrap #nav_global > ul > li p span {
  display: block;
  margin-top: 7px;
  font-size: 0.7em;
}

#hednav .wrap .navwrap #nav_global > ul > li p:after {
  border: solid 1px #ddd;
  font-weight: 100;
  content: "\f067";
  font-family: "Font Awesome 5 Pro";
  display: block;
  position: absolute;
  right: 0;
  top: -1px;
  bottom: 0;
  width: 77px;
  height: 77px;
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f7f6;
}

#hednav .wrap .navwrap #nav_global > ul > li p.open:after {
  content: "\f00d";
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

@media all and (-ms-high-contrast: none) {
  .MenuOpened .global-menu-btn::before {
    content: "\f00d";
    font-family: "Font Awesome 5 pro";
  }
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
  margin-left: auto;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#mainArea img {
  width: 100% !important;
}

#local-keyvisual {
  background: url(../images/contents/main_2.jpg);
  overflow: hidden;
  position: relative;
  background-position: center;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual #local-keyvisual-title {
    width: 100%;
  }
}

#local-keyvisual #local-keyvisual-title h1 {
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1;
  color: #fff !important;
  z-index: 1;
  position: relative;
  background: rgba(34, 24, 21, 0.4);
  text-shadow: 0 0 10px rgba(34, 24, 21, 0.8);
  text-align: center;
  padding: 200px 10px;
  display: block;
  line-height: 1.4em;
  font-size: 50px;
  font-size: 4.16667vw;
}

@media only screen and (min-width: 1600px) {
  #local-keyvisual #local-keyvisual-title h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual #local-keyvisual-title h1 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 834px) {
  #local-keyvisual #local-keyvisual-title h1 {
    font-size: 35px;
    padding: 100px 10px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual #local-keyvisual-title h1 {
    padding: 150px 10px;
  }
}

#local-keyvisual #local-keyvisual-title h1:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-title h1 span {
  font-size: 0.5em;
  display: block;
  line-height: 1;
  margin-top: 15px;
}

#local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.pan1 {
  padding-top: 30px !important;
  margin: 0px 0 50px;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #221815;
}

.mean-container .mean-nav ul li a.mean-expand {
  font-size: 22px !important;
  width: 15px !important;
  height: 12px !important;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  color: #fff;
}

footer#global_footer a {
  color: #fff;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

#footer {
  color: #0b0b0b;
  background: #1f4589;
  padding: 50px 10px;
}

@media only screen and (max-width: 1200px) {
  #footer {
    padding: 30px 10px;
  }
}

#footer .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  #footer .inner {
    display: block;
  }
}

#footer .flxL {
  width: 40%;
  text-align: left;
}

@media only screen and (max-width: 834px) {
  #footer .flxL {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}

#footer .flxL .ttl {
  color: #fff;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 10px;
}

#footer .flxL .tel2 {
  margin-bottom: 0px;
}

#footer .flxL .tel2 a {
  font-size: 20px;
  font-weight: 800;
  color: #fff;
}

#footer .flxL .tel2 a span {
  font-size: 0.7em;
  margin-right: 10px;
}

#footer .flxL .fax {
  font-size: 20px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 10px;
}

#footer .flxL .fax span {
  font-size: 0.7em;
  margin-right: 10px;
}

#footer .flxL .cont a {
  display: block;
}

#footer .flxL .cont a img {
  max-width: 70%;
}

#footer .flxR {
  width: 58%;
  text-align: right;
}

@media only screen and (max-width: 834px) {
  #footer .flxR {
    width: 100%;
    text-align: center;
  }
}

#footer .flxR iframe {
  width: 100% !important;
}

@media only screen and (max-width: 640px) {
  #footer nav {
    display: none;
  }
}

#footer nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

#footer nav ul li:before {
  content: "|";
}

#footer nav ul li:last-child:after {
  content: "|";
}

#footer nav ul li a {
  padding: 0 15px;
  color: #0b0b0b;
}

#footer #siteID {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

#footer #siteID img {
  width: 100% !important;
  max-width: calc( 652px / 2) !important;
}

#copy {
  background: #333;
  padding: 10px 0;
  text-align: center;
  font-size: 13px;
  display: block;
  line-height: 1.7em;
  color: #fff;
}

@media only screen and (max-width: 640px) {
  #copy {
    text-align: center;
  }
}

#copy a {
  font-size: 13px;
  color: #fff !important;
  text-decoration: none;
}

#copy a:hover {
  opacity: 0.6;
}

.btn {
  overflow: hidden;
  position: relative;
  display: block;
  max-width: 350px;
  margin: 0 auto;
}

.btn .su_button_circle {
  background-color: #0a1e42;
  border-radius: 1000px;
  position: absolute;
  left: 0;
  top: 0;
  width: 0px;
  height: 0px;
  margin-left: 0px;
  margin-top: 0px;
  pointer-events: none;
}

.btn .explode-circle {
  animation: explode 0.5s forwards;
}

.btn .desplode-circle {
  animation: desplode 0.5s forwards;
}

.btn a {
  opacity: 1 !important;
  text-align: center;
  transition: 400ms;
  text-decoration: none;
  z-index: 2;
  background: #222;
  font-size: 18px;
  display: block;
  padding: 25px 10px;
  line-height: 1;
  color: #fff;
}

.btn a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f178";
  position: absolute;
  right: 20px;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn a:hover {
  opacity: 1 !important;
}

.btn a span {
  position: relative;
  z-index: 1;
}

@keyframes explode {
  0% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(31, 69, 137, 0.2);
  }
  100% {
    width: 900px;
    height: 900px;
    margin-left: -450px;
    margin-top: -450px;
    background-color: rgba(31, 69, 137, 0.8);
  }
}

@keyframes desplode {
  0% {
    width: 900px;
    height: 900px;
    margin-left: -450px;
    margin-top: -450px;
    background-color: rgba(31, 69, 137, 0.8);
  }
  100% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(31, 69, 137, 0.6);
  }
}

.hoverimg a:hover {
  opacity: 1 !important;
}

.hoverimg .box {
  position: relative;
}

.hoverimg .box img {
  opacity: 1 !important;
}

.hoverimg .box a:hover {
  opacity: 1 !important;
}

.hoverimg .box::before, .hoverimg .box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 0, 1);
  transition: transform .3s ease-out 0s;
  background: rgba(255, 255, 255, 0.1);
  content: '';
  pointer-events: none;
}

.hoverimg .box::before {
  transform-origin: left top;
}

.hoverimg .box::after {
  transform-origin: right bottom;
}

.hoverimg .box:hover::before, .hoverimg .box:hover::after, .hoverimg .box:focus::before, .hoverimg .box:focus::after {
  transform: scale3d(1, 1, 1);
}

@media only screen and (max-width: 1200px) {
  .cont_1 .inner {
    padding: 0 10px;
  }
}

.cont_1 .inner .wrap {
  justify-content: space-between !important;
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner .wrap {
    display: block;
  }
}

.cont_1 .inner .wrap .box {
  width: 32%;
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner .wrap .box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.cont_1 .inner .wrap .box a {
  display: block;
}

.cont_1 .inner .wrap .box img {
  width: 100% !important;
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner .wrap .box img {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_2 .inner {
    padding: 0 10px;
  }
}

.cont_3 .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .cont_3 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_3 .inner {
    display: block;
  }
}

.cont_3 .inner .flxL {
  width: 48%;
}

@media only screen and (max-width: 834px) {
  .cont_3 .inner .flxL {
    width: 100%;
    margin-bottom: 20px;
  }
  .cont_3 .inner .flxL img {
    width: 100% !important;
  }
}

.cont_3 .inner .flxR {
  width: 48%;
}

@media only screen and (max-width: 834px) {
  .cont_3 .inner .flxR {
    width: 100%;
  }
}

@media only screen and (max-width: 834px) {
  .cont_3 .inner .flxR #pageplugin {
    width: 100%;
    text-align: center;
    margin: auto;
  }
}

.newswrap {
  background: #f8f8f8;
  padding: 20px;
  border: 1px solid #ccc;
  height: 360px;
  overflow-y: auto;
}

@media only screen and (max-width: 834px) {
  .newswrap {
    padding: 20px 0 20px 20px;
  }
}

.newswrap .news {
  z-index: 1;
  position: relative;
  height: 100%;
  padding-right: 40px;
}

.newswrap .news .blog_list {
  border: 0;
}

.newswrap .news .blog_list .blog_photo {
  display: none;
}

.newswrap .news .blog_list > div {
  border-bottom: 1px dashed #333;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

.newswrap .news .blog_list .blog_text {
  width: 100%;
  padding: 0;
  font-size: 16px;
}

.newswrap .news .blog_list .blog_text .detawrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.newswrap .news .blog_list .blog_text .detawrap .wrapdeta .blog_date {
  line-height: 1;
  font-size: 16px;
  font-weight: 800;
  color: #0a1e42;
}

.newswrap .news .blog_list .blog_text .detawrap .wrapdeta ul {
  display: none;
}

.newswrap .news .blog_list .blog_text .detawrap .wrapdeta ul li a {
  margin-left: 10px;
  display: block;
  font-size: 12px;
  border: 1px solid #0a1e42;
  color: #0a1e42;
  border-radius: 20px;
  padding: 3px 10px;
}

.newswrap .news .blog_list .blog_text h3 {
  margin-bottom: 0;
}

.newswrap .news .blog_list .blog_text h3 a {
  font-weight: 400;
  font-size: 16px;
  color: #0a1e42;
}

.newswrap .news .blog_list .blog_text .detail {
  display: none;
}

.newswrap .news dl {
  padding: 15px 0;
  border-bottom: 1px dashed #333;
  display: block !important;
}

.newswrap .news dl dt, .newswrap .news dl dd {
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

.cont_4:nth-child(even) .inner {
  flex-direction: row-reverse;
}

.cont_4 .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .cont_4 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_4 .inner {
    display: block;
  }
}

.cont_4 .inner .flxL {
  width: 48%;
}

@media only screen and (max-width: 834px) {
  .cont_4 .inner .flxL {
    width: 100%;
    margin-bottom: 20px;
  }
  .cont_4 .inner .flxL img {
    width: 100% !important;
  }
}

.cont_4 .inner .flxR {
  width: 48%;
}

@media only screen and (max-width: 834px) {
  .cont_4 .inner .flxR {
    width: 100%;
  }
}

/* title */
h2.mail.title {
  display: none !important;
}

.midashi h2 {
  font-weight: 800;
  line-height: 1.7em;
  color: #222;
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  .midashi h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi h2 {
    font-size: 20px;
  }
}

.midashi2 {
  background: #414141;
}

.midashi2 h2 {
  padding: 15px 10px;
  font-weight: 800;
  line-height: 1;
  color: #fff;
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi2 h2 {
    font-size: 20px;
  }
}

.midashi3 h2 {
  font-weight: 700;
  padding: 20px 15px;
  color: #0a1e42;
  text-shadow: 0 0 5px white;
  border-left: solid 7px #010079;
  border-bottom: solid 3px #ccc;
  background: #f7f7f7;
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  .midashi3 h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi3 h2 {
    font-size: 18px;
  }
}

.midashi4 h3 {
  font-weight: 700;
  color: #0a1e42;
  padding-bottom: 15px;
  border-bottom: solid 2px #333;
  font-size: 20px;
}

@media only screen and (max-width: 1200px) {
  .midashi4 h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi4 h3 {
    font-size: 18px;
  }
}

.caution01 {
  padding: 50px;
  background: #fff2c4;
}

.caution01 b {
  font-size: 20px;
  font-weight: 700;
}

.wire01 {
  background: #def0fb;
  border: 1px solid #ccc;
}

.wire01 .inner {
  max-width: 700px;
  padding: 100px 10px;
}

@media only screen and (max-width: 834px) {
  .wire01 .inner {
    padding: 30px;
  }
}

.conv {
  margin: 0 auto;
  font-size: 15px;
  position: relative;
  line-height: 1.8;
}

.musume1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 0 25px 0;
}

.musume1 .musume2 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: url(../images/contents/icon_1.png) no-repeat center #fff;
}

.musume1 .musume3 {
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 17px 13px 15px 18px;
  border-radius: 12px;
  background: #fff;
  box-sizing: border-box;
  line-height: 1.5;
  margin-left: 20px;
}

.musume1 .musume3:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  left: -26px;
  border-right: 22px solid #fff;
}

.papa1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  margin: 0 0 25px 0;
}

.papa1 .papa2 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: url(../images/contents/icon_2.png) no-repeat center #fff;
}

.papa1 .papa3 {
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 17px 13px 15px 18px;
  border-radius: 12px;
  background: #7cfe7d;
  box-sizing: border-box;
  line-height: 1.5;
  margin-right: 20px;
}

.papa1 .papa3:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  right: -26px;
  border-left: 22px solid #7cfe7d;
}

.son1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 0 25px 0;
}

.son1 .son2 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: url(../images/contents/icon_3.png) no-repeat center #fff;
}

.son1 .son3 {
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 17px 13px 15px 18px;
  border-radius: 12px;
  background: #fff;
  box-sizing: border-box;
  line-height: 1.5;
  margin-left: 20px;
}

.son1 .son3:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  left: -26px;
  border-right: 22px solid #fff;
}

.jo1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 0 25px 0;
}

.jo1 .jo2 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: url(../images/contents/icon_4.png) no-repeat center #fff;
}

.jo1 .jo3 {
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 17px 13px 15px 18px;
  border-radius: 12px;
  background: #fff;
  box-sizing: border-box;
  line-height: 1.5;
  margin-left: 20px;
}

.jo1 .jo3:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  left: -26px;
  border-right: 22px solid #fff;
}

.wireTable {
  width: 560px;
  font-size: 13px;
  margin: 10px 0;
}

@media only screen and (max-width: 834px) {
  .wireTable {
    width: 100%;
  }
}

.wireTable .tableindnt {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.wireTable.thc th {
  text-align: center;
}

@media only screen and (max-width: 834px) {
  .wireTable .spthc {
    text-align: center;
  }
}

.wireTable th, .wireTable td {
  padding: 10px;
}

@media only screen and (max-width: 834px) {
  .wireTable th, .wireTable td {
    width: 100%;
  }
}

.wireTable th {
  border: 1px #999 solid;
  background: #fff;
}

@media only screen and (max-width: 834px) {
  .wireTable th {
    display: block !important;
  }
}

.wireTable th span {
  display: block;
  text-align: center;
}

.wireTable td {
  border: 1px #999 solid;
  background: #fff;
}

@media only screen and (max-width: 834px) {
  .wireTable td {
    display: block !important;
  }
}

.character {
  position: absolute;
  display: none;
}

#ot {
  position: fixed;
  bottom: 150px;
  right: 20px;
}

#other {
  position: fixed;
  bottom: 150px;
  left: 50px;
  z-index: 0;
}

.ullist li {
  background: #f7f7f7;
  border: 1px solid #ccc;
  padding: 15px;
}

.works01 {
  justify-content: space-between !important;
  margin-left: -2.4390%;
  justify-content: flex-start !important;
  margin-bottom: 80px;
}

@media only screen and (max-width: 640px) {
  .works01 {
    margin-left: 0;
    justify-content: space-between !important;
  }
}

.works01 .box {
  width: 30.8943%;
  margin-left: 2.4390%;
}

@media only screen and (max-width: 640px) {
  .works01 .box {
    width: 48%;
    margin-left: 0%;
  }
}

.works01 .box a {
  display: block;
  margin-bottom: 15px;
}

.ext_01 {
  justify-content: space-between !important;
  flex-wrap: wrap;
}

.ext_01 .box {
  margin-bottom: 20px;
  width: 48%;
  text-align: center;
}

.ext_01 .box img {
  width: 100% !important;
}

.ext_01 .box > div {
  margin-top: 15px;
}

.ext_01 .box h3 {
  display: none;
}

.ext_02 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .ext_02 {
    flex-wrap: wrap;
  }
}

.ext_02:nth-child(even) {
  flex-direction: row-reverse;
}

.ext_02 .flxL {
  width: 55%;
}

@media only screen and (max-width: 1000px) {
  .ext_02 .flxL {
    width: 50%;
  }
}

@media only screen and (max-width: 834px) {
  .ext_02 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.ext_02 .flxR {
  width: 40%;
}

@media only screen and (max-width: 1000px) {
  .ext_02 .flxR {
    width: 45%;
  }
}

@media only screen and (max-width: 834px) {
  .ext_02 .flxR {
    width: 100%;
  }
}

.ext_02 .flxR img {
  width: 100% !important;
}

.ext_03 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .ext_03 {
    flex-wrap: wrap;
  }
}

.ext_03:nth-child(even) {
  flex-direction: row-reverse;
}

.ext_03 .flxL {
  width: 55%;
}

@media only screen and (max-width: 1000px) {
  .ext_03 .flxL {
    width: 50%;
  }
}

@media only screen and (max-width: 834px) {
  .ext_03 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.ext_03 .flxR {
  width: 40%;
}

@media only screen and (max-width: 1000px) {
  .ext_03 .flxR {
    width: 45%;
  }
}

@media only screen and (max-width: 834px) {
  .ext_03 .flxR {
    width: 100%;
  }
}

.ext_03 .flxR img {
  width: 100% !important;
}

.comp2 dt, .comp2 dd {
  background: none !important;
  border: none !important;
  padding: 1.5em 1em !important;
  font-size: 1rem;
}

.comp2 dt {
  border-bottom: 1px solid #ccc !important;
}

.comp2 dd {
  border-bottom: 1px solid #eee !important;
}

@media only screen and (max-width: 834px) {
  .comp2 dl {
    border: 1px solid #ccc !important;
    display: flex;
    flex-flow: column;
  }
  .comp2 dt {
    border-bottom: 0 !important;
    background: #ccc !important;
    font-weight: bold;
    border-bottom: 1px solid #ccc !important;
  }
  .comp2 dt, .comp2 dd {
    padding: 5px !important;
    display: block;
    width: 100% !important;
  }
}

.waku {
  padding: 40px;
  background: #fff;
  border: double 7px #0a1e42;
}

@media only screen and (max-width: 640px) {
  .waku {
    padding: 20px;
  }
}

.waku h3 {
  padding-bottom: 10px;
  border-bottom: 1px solid #4e362a;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: #222;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.5vw;
}

@media only screen and (min-width: 1600px) {
  .waku h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1000px) {
  .waku h3 {
    font-size: 18px;
  }
}

.media3 .sns_list {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .media3 .sns_list {
    margin-left: -2.43902%;
  }
}

@media only screen and (max-width: 640px) {
  .media3 .sns_list {
    justify-content: space-between;
    margin-left: 0;
  }
}

.media3 .sns_list > div {
  border-bottom: 0;
  box-shadow: 0 0px 8px rgba(51, 51, 51, 0.2);
  width: 30.89431%;
  height: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 2.43902%;
  display: block;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .media3 .sns_list > div {
    width: 30.89431%;
    margin-left: 2.43902%;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .media3 .sns_list > div {
    width: 48%;
    margin-left: 0;
  }
}

.media3 .sns_list > div:nth-child(n+7) {
  margin-bottom: 0;
}

.media3 .sns_list > div .sns_text {
  width: 100%;
  font-size: 16px;
}

.media3 .sns_list > div .sns_text .sns_date {
  color: #0b0b0b;
  font-weight: 700;
  font-size: 18px;
  border-bottom: 1px solid #0b0b0b;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.media3 .sns_list > div .sns_photo {
  width: 100%;
  padding: 0;
}

.media3 .sns_list > div .sns_photo a {
  display: block;
  position: relative;
}

.media3 .sns_list > div .sns_photo a:before {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
}

.media3 .sns_list > div .sns_photo img {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.contactwrap {
  background: #f7f7f7;
  padding: 50px;
}

.policy a {
  color: #333;
}

.img100 img {
  max-width: 100% !important;
  width: 100% !important;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 834px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}
